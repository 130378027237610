"use client";
import { useContext } from 'react';
import { CartContext } from '@/context/CartContext';
import { Plus } from 'lucide-react';
import { Minus } from 'lucide-react';

const UpdateCart = ({ data }) => {
    const { incrementItem, decrementItem } = useContext(CartContext);
    return (
        <div className="flex items-center">
            <button onClick={() => decrementItem(data)} className='mr-1 cursor-pointer'>
                <Minus size={16} />
            </button>
            <p className="m-0 border text-center px-2.5 py-1">{data?.quantity}</p>
            <button onClick={() => incrementItem(data)} className='ml-1 cursor-pointer'>
                <Plus size={16} />
            </button>
        </div>
    )
}

export default UpdateCart
