"use client";
import UpdateCart from "@/app/cart/components/UpdateCart";
import { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

const CartContextProvider = (props) => {
  const [cart, setCart] = useState(
    typeof window !== "undefined" && localStorage.getItem("ich-cart")
      ? JSON.parse(localStorage.getItem("ich-cart"))
      : []
  );

  const [orderProducts, setOrderProducts] = useState(
    typeof window !== "undefined" && localStorage.getItem("ichOrder-products")
      ? JSON.parse(localStorage.getItem("ichOrder-products"))
      : []
  );
  const [customerData, setCustomerData] = useState({});

  useEffect(() => {
    localStorage.setItem("ich-cart", JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    const cartItems = localStorage.getItem("ich-cart");
    if (cartItems) {
      setCart(JSON.parse(cartItems));
    }
  }, []);

  useEffect(() => {
    const customerInfo = localStorage.getItem("customer-information");
    if (customerInfo) {
      setCustomerData(JSON.parse(customerInfo));
    }
  }, []);

  const addToCart = (item) => {
    const existingItem = cart.find((cartItem) => cartItem?._id === item?._id);

    if (existingItem) {
      const updatedCart = cart.map((cartItem) =>
        cartItem._id === item._id
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      );
      setCart(updatedCart);
    } else {
      const newItem = { ...item, quantity: 1 };
      setCart([...cart, newItem]);
    }
  };

  const setAddToCart = (items) => {
    let updatedCode = [...cart]; // Copy the current cart state

    items?.forEach((item) => {
      const existingItem = updatedCode.find(
        (cartItem) => cartItem?._id === item?._id
      );

      if (existingItem) {
        // If the item already exists, update its quantity
        updatedCode = updatedCode.map((cartItem) =>
          cartItem?._id === item?._id
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      } else {
        // If the item doesn't exist, add it to the cart with quantity 1
        updatedCode.push({ ...item, quantity: 1 });
      }
    });

    setCart([...updatedCode]);
  };

  // Example usage:
  // setAddToCart([{ _id: 1, name: "Product A" }, { _id: 2, name: "Product B" }]);

  const removeFromCart = (data) => {
    const updatedCart = cart.filter((item) => item?._id !== data?._id);
    setCart(updatedCart);
  };

  const incrementItem = (data) => {
    const updatedCart = cart.map((item) => {
      if (item?._id === data?._id) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
    setCart(updatedCart);
  };

  const decrementItem = (data) => {
    const updatedCart = cart.map((item) => {
      if (item?._id === data?._id && item.quantity > 1) {
        return { ...item, quantity: item.quantity - 1 };
      }
      return item;
    });
    setCart(updatedCart);
  };

  const orderProductIncrementItem = (data) => {
    const updatedCart = orderProducts?.map((item) => {
      if (item?._id === data?._id) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
    setOrderProducts(updatedCart);
  };

  const orderProductDecrementItem = (data) => {
    const updatedCart = orderProducts?.map((item) => {
      if (item?._id === data?._id && item.quantity > 1) {
        return { ...item, quantity: item.quantity - 1 };
      }
      return item;
    });
    setOrderProducts(updatedCart);
  };

  const getTotalPrice = () => {
    const total = cart.reduce((acc, item) => {
      return acc + item.discountedPrice * item.quantity;
    }, 0);
    return Math.round(total);
  };

  const getOrderProductsTotalPrice = (orderProducts) => {
    const total = orderProducts.reduce((acc, item) => {
      return acc + item.discountedPrice * item.quantity;
    }, 0);
    return Math.round(total);
  };

  const addOrderProduct = (item) => {
    localStorage.setItem("ichOrder-products", JSON.stringify(item));
    setOrderProducts(item);
  };

  const getActualProductPrice = (orderProducts) => {
    const total = orderProducts.reduce((acc, item) => {
      return acc + item.price * item.quantity;
    }, 0);
    return Math.round(total);
  };

  const getCustomerShippingInfo = () => {
    return customerData;
  };

  const getCustomerTag = (products) => {
    const tags = products?.map((item) => {
      return { value: item.type, label: item.type };
    });
    return tags;
  };

  const getCustomerOrders = (orders, status) => {
    let data = [];
    if (status === "delivered") {
      data = orders?.filter(
        (order) =>
          order?.orderStatus === status || order?.orderStatus === "fullfilled"
      );
    } else {
      data = orders?.filter((order) => order?.orderStatus === status);
    }

    return data;
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        orderProducts,
        getOrderProductsTotalPrice,
        addOrderProduct,
        addToCart,
        removeFromCart,
        incrementItem,
        decrementItem,
        getTotalPrice,
        getActualProductPrice,
        getCustomerShippingInfo,
        getCustomerTag,
        getCustomerOrders,
        orderProductIncrementItem,
        orderProductDecrementItem,
        setAddToCart,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
